import { CheckIcon } from "lucide-react";

export default function Features() {
  const features = [
    {
      name: "Where did I find that recipe?",
      icon: <CheckIcon className="h-10 w-10 text-white" />,
      imageUrl: "https://images.pexels.com/photos/6963740/pexels-photo-6963740.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      name: "How do I plan meals?",
      icon: <CheckIcon className="h-10 w-10 text-white" />,
      imageUrl: "https://images.pexels.com/photos/19915772/pexels-photo-19915772/free-photo-of-woman-writing-notes-on-calendar.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      name: "What's with all of the ads?",
      icon: <CheckIcon className="h-10 w-10 text-white" />,
      imageUrl: "https://images.pexels.com/photos/802024/pexels-photo-802024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      name: "What about my shopping list?",
      href: "/collection",
      icon: <CheckIcon className="h-10 w-10 text-white" />,
      imageUrl: "https://images.pexels.com/photos/7129160/pexels-photo-7129160.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      name: "How do I tweak recipes?",
      icon: <CheckIcon className="h-10 w-10 text-white" />,
      imageUrl: "https://images.pexels.com/photos/1629212/pexels-photo-1629212.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      name: "How do I share my favorite recipes?",
      icon: <CheckIcon className="h-10 w-10 text-white" />,
      imageUrl: "https://images.pexels.com/photos/3761509/pexels-photo-3761509.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },

  ];

  return (
    <div className="bg-white py-20 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* <div className="mx-auto max-w-2xl lg:text-center">Try it out!</div> */}
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <p className="mt-2 font-lexend text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Do you like cooking</p>
                <p className="mt-2 font-lexend text-3xl font-bold  tracking-tight text-gray-900  sm:text-4xl">but feel <span className="italic">overwhelmed</span>?</p>
                {/* <p className="mt-2 text-lg leading-8 text-gray-600">
                  Learn how Forked is revolutionizing the way you cook.
                </p> */}
              </div>
              <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {features.map((feature, index) => (
                  <div key={index} className="flex flex-col items-start justify-between">
                    <div className="relative w-full">
                      <img
                        src={feature.imageUrl}
                        alt=""
                        className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                      />
                      <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="max-w-xl">

                      <div className="group relative">
                        <h3 className="mt-3 text-lg font-lexend font-semibold leading-6 text-gray-900 group-hover:text-gray-600">

                          <span className="absolute inset-0" />
                          {feature.name}

                        </h3>
                        <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{feature.description}</p>
                      </div>

                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => {
              const content = (
                <div key={feature.name} className="relative pl-16">
                  <div className="font-lexend text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-14 w-14 items-center justify-center rounded-lg bg-theme-600">{feature.icon}</div>
                    <h3>{feature.name}</h3>
                  </div>
                  <div className="mt-2 text-base leading-7 text-gray-600">{feature.description}</div>
                </div>
              );
              if (feature.href) {
                return (
                  <Link to={feature.href} key={feature.name}>
                    {content}
                  </Link>
                );
              }
              return content;
            })}
          </div> */}
        </div>
      </div>
    </div>
  );
}
