import { CheckIcon, XIcon } from 'lucide-react';
import { Fragment } from 'react';
import QuestionMarkFilledIcon from '~/components/ui/icons/QuestionMarkFilledIcon';

export default function Comparison() {

    const checkmark = <CheckIcon className={"text-green-500 h-5 w-5"} />;
    const checkUnlimited = <div className={'flex'}><CheckIcon className={"text-green-500 h-5 w-5"} /><span className='pl-2'> Unlimited</span></div>;
    const xmark = <XIcon className={"text-red-500 h-5 w-5"} />;
    const question = <QuestionMarkFilledIcon className={"text-gray-500 h-5 w-5"} />;

    const locations = [
        {
            name: 'Recipes',
            people: [
                { name: 'Save Recipes', title: checkUnlimited, email: checkUnlimited, role: xmark },
                { name: 'Create Collections', title: checkUnlimited, email: xmark, role: xmark },
                { name: 'Change Servings', title: checkmark, email: checkmark, role: xmark },
                { name: 'Modify Recipes', title: checkmark, email: xmark, role: xmark },
                { name: 'Side-by-side View', title: checkmark, email: xmark, role: xmark },
                { name: 'Nutritional Info', title: checkmark, email: xmark, role: xmark },
                { name: 'Timer', title: checkmark, email: xmark, role: xmark },
            ],
        },
        {
            name: 'Meal Planning',
            people: [
                { name: 'Weekly Meal Plan', title: checkmark, email: xmark, role: checkmark },
                { name: 'Create Your Own', title: checkmark, email: xmark, role: checkmark },
                { name: 'Plan Day/Time', title: checkmark, email: xmark, role: xmark },
                { name: 'Plan Generic (This Week)', title: checkmark, email: xmark, role: xmark },
            ],
        },
        {
            name: 'Shopping',
            people: [
                { name: 'Smart Shopping List', title: checkmark, email: xmark, role: checkmark },
                { name: 'Adds to Pantry', title: checkmark, email: xmark, role: xmark },
                { name: 'Smart Merging', title: checkmark, email: xmark, role: checkmark },
            ],
        },
        {
            name: 'Other',
            people: [
                { name: 'Desktop', title: checkmark, email: checkmark, role: checkmark },
                { name: 'iOS', title: checkmark, email: checkmark, role: checkmark },
                { name: 'Android', title: checkmark, email: checkmark, role: checkmark },
                { name: 'Frequent Updates', title: checkmark, email: xmark, role: question },
                { name: 'Intuitive UI', title: checkmark, email: question, role: question },
                { name: 'Premium Support', title: checkmark, email: question, role: question },
            ],

        },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    return (
        <div className="container mx-auto space-y-12 px-5 py-24">

            <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                <h2 className="text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">How do we compare?</h2>
                <p className="text-center text-xl text-gray-500">Let's see how Forked compares to some other popular options</p>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full">
                            <thead className="bg-white">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                        Feature
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left font-lexend text-sm font-semibold text-gray-900">
                                        Forked
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        JustTheRecipe
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        eMeals
                                    </th>

                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {locations.map((location) => (
                                    <Fragment key={location.name}>
                                        <tr className="border-t border-gray-200">
                                            <th
                                                colSpan={5}
                                                scope="colgroup"
                                                className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-3"
                                            >
                                                {location.name}
                                            </th>
                                        </tr>
                                        {location.people.map((person, personIdx) => (
                                            <tr
                                                key={personIdx}
                                                className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                            >
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                    {person.name}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.title}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.email}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.role}</td>

                                            </tr>
                                        ))}
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}