import { ActionFunctionArgs, json, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { Link, useLoaderData, useNavigate } from "@remix-run/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTypedActionData } from "remix-typedjson";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import { getTranslations } from "~/locale/i18next.server";
import { defaultFooterBlock } from "~/modules/pageBlocks/components/blocks/marketing/footer/FooterBlockUtils";
import FooterVariantSimple from "~/modules/pageBlocks/components/blocks/marketing/footer/FooterVariantSimple";
import { PricingBlockService } from "~/modules/pageBlocks/components/blocks/marketing/pricing/PricingBlockService.server";
import TestimonialsVariantSimple from "~/modules/pageBlocks/components/blocks/marketing/testimonials/TestimonialsVariantSimple";
import { CallToAction, Container } from "~/modules/recipe/components/CallToAction";
import Comparison from "~/modules/recipe/components/Comparison";
import Features from "~/modules/recipe/components/Pages/Features";
import FreeTrial from "~/modules/recipe/components/Pages/FreeTrial";
import Headline from "~/modules/recipe/components/Pages/Headline";
import Homepage from "~/modules/recipe/components/Pages/Homepage";
import { getScreenshot } from "~/modules/recipe/db/media.db.server";
import { getRandomApprovedRecipes } from "~/modules/recipe/db/recipeFlat.db.server";
import AuthService from "~/modules/users/services/AuthService";
import { createCacheHeader } from "~/utils/cache/cache";
import { useRootData } from "~/utils/data/useRootData";

export const meta: MetaFunction = ({ data }) => {
  const meta = data?.metaTags ?? [];
  return [
    ...meta,
    { title: "Forked | Recipe App without Ads" },
    { property: "og:title", content: "Discover Forked: The Ultimate Ad-Free Recipe App for Organizing and Customizing Your Favorite Recipes" },
    { property: "og:url", content: "https://www.forked.cooking" },
    { property: "og:image", content: "https://og.forked.cooking/og/homepage1686935387156.png" },
    { property: "og:image:alt", content: "Forked Recipe App" },
    {
      property: "og:description",
      content:
        "Unlock the power of Forked, the premier ad-free recipe app that revolutionizes your culinary journey. Save and customize recipes from any website, effortlessly organize collections, and plan your meals with ease. With automatic shopping list integration, never miss an ingredient again. Experience the joy of cooking like never before with Forked.",
    },
    { property: "og:type", content: "website" },
  ];
};
export let headers = ({ loaderHeaders }) => {
  return { "Cache-Control": loaderHeaders.get("Cache-Control") };
};

export const shouldRevalidate = () => false;
export const loader = async ({ request, params }: LoaderFunctionArgs) => {
  // return redirect("/login");
  // console.log("called index loader");
  // const page = await getCurrentPage({ request, params, slug: "/" });
  const recipes = (await getRandomApprovedRecipes(20)) || [];
  const landingPageImages = await getScreenshot("landing");
  return json(
    { recipes, images: landingPageImages?.map((img) => `${process.env.CDN_IMG}${img.filename}`) },
    {
      ...createCacheHeader(1, 60),
    }
  );
};

type ActionData = { error?: string; success?: string; authRequired?: boolean };
export const action = async ({ request, params }: ActionFunctionArgs) => {
  const { t } = await getTranslations(request);
  const form = await request.formData();
  const action = form.get("action");
  if (action === "login") {
    return await AuthService.loginFromRequest(request, form);
  } else if (action === "subscribe") {
    return await PricingBlockService.subscribe({ request, params, form, t });
  }
};

export default function IndexRoute() {
  const rootData = useRootData();
  const data = useLoaderData();
  const actionData = useTypedActionData<ActionData>();
  const navigate = useNavigate();
  const openAccount = useAccountSelectorContext((state) => state.openAccount);
  // const [blocks, setBlocks] = useState(data.blocks);
  const [loginModalOpen, setLoginModalOpen] = useState<boolean>(false);
  useEffect(() => {
    try {
      // @ts-ignore
      $crisp.push(["do", "chat:show"]);
    } catch {
      // ignore
    }
  }, []);

  useEffect(() => {
    if (actionData?.success) {
      toast.success(actionData.success, {
        position: "bottom-right",
      });
    } else if (actionData?.error) {
      toast.error(actionData.error);
    }
    if (actionData?.authRequired) {
      setLoginModalOpen(true);
    }
  }, [actionData]);

  useEffect(() => {
    if (rootData.user) {
      setLoginModalOpen(false);
    }
  }, [rootData.user]);



  const loggedIn = useMemo(() => {
    return rootData.hasSub;
  }, [rootData.hasSub])

  const handleEnter = useCallback(() => {
    if (loggedIn) {
      navigate("/app");
    } else {
      openAccount("click", "free_trial");
    }
  }, [loggedIn, navigate, openAccount]);

  return (
    <>
      <FreeTrial loggedIn={rootData.hasSub} />
      <Homepage recipes={data?.recipes} loggedIn={rootData.hasSub} />

      <Features />
      <CallToAction />

      <Headline />


      <TestimonialsVariantSimple
        item={{
          style: "simple",
          headline: "Don't just take our word for it",
          subheadline: "Read what our customers have to say",
          items: [
            {
              name: "Andrea M.",
              role: "Home Chef",
              company: "",
              img: "https://forked-og.b-cdn.net/images/users/andrea.jpg",

              quote: "Everything is in one place. Plus, no annoying ads!",
            },
            {
              name: "Emily A.",
              role: "Home Cook",
              company: "",
              img: "https://forked-og.b-cdn.net/images/users/emily.jpg",

              quote: "Planning meals and shopping has never been easier thanks to Forked's intuitive interface and smart features.",
            },
            {
              name: "John R.",
              role: "Food Blogger",
              company: "",
              img: "https://forked-og.b-cdn.net/images/users/john.jpg",

              quote: "With Forked, meal planning becomes a breeze, allowing me to curate enticing menus and keep my blog fresh and exciting.",
            },
            {
              name: "Beth T.",
              role: "Busy Professional",
              company: "",
              img: "https://forked-og.b-cdn.net/images/users/beth.jpg",

              quote: "Forked has made my life so much easier. I can search anywhere for the best recipes and save them all in one place.",
            },
            {
              name: "Davis C.",
              role: "Chef",
              company: "",
              img: "https://forked-og.b-cdn.net/images/users/davis.jpg",

              quote: "Streamlining my cooking routine, Forked empowers me with organized meal plans and efficient shopping lists.",
            },
            {
              name: "Brenda",
              role: "Parent",
              company: "",
              img: "https://forked-og.b-cdn.net/images/users/brenda.jpg",

              quote: "Preparing nutritious meals for my family has become a breeze, thanks to Forked's family-friendly recipes and meal planning capabilities.",
            },
            {
              name: "Charity H.",
              role: "Mom",
              company: "",
              img: "https://forked-og.b-cdn.net/images/users/charity.jpg",

              quote: "I can plan my entire week in 5 minutes. Then, with a pre-made shopping list, I can get in and out of the grocery store in no time.",
            },
          ],
        }}
      />
      <section
        className="relative overflow-hidden bg-theme-300 py-20"
      >
        {/* <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
          <CircleBackground color="#fff" className="animate-spin-slower" />
        </div> */}
        <Container className="relative">
          <div className="mx-auto max-w-md sm:text-center">
            <h2 className="text-4xl font-lexend font-bold tracking-tight text-white">
              Still not convinced?
            </h2>
            <div className="mt-2 flex justify-center ">
              <Link className="mt-10 px-5 py-3 shadow-md font-lexend bg-white rounded-lg !text-md" to={'/learn-more'}>
                Learn More
              </Link>
            </div>
          </div>
        </Container>
      </section>
      <Comparison />
      <section
        className="relative overflow-hidden bg-theme-600 py-20"
      >
        {/* <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
          <CircleBackground color="#fff" className="animate-spin-slower" />
        </div> */}
        <Container className="relative">
          <div className="mx-auto max-w-md sm:text-center">
            <h2 className="text-4xl font-lexend font-bold tracking-tight text-white">
              Ready to get Forked?
            </h2>
            <div className="mt-2 flex justify-center ">
              <div className="mt-10 px-5 py-3 shadow-md font-lexend bg-white rounded-lg !text-md" onClick={handleEnter}>
                Let's Go!
              </div>
            </div>
          </div>
        </Container>
      </section>
      <FooterVariantSimple item={defaultFooterBlock} />
    </>
  );
}
