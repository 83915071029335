import { useNavigate } from "@remix-run/react";
import { useCallback } from "react";
import { useAccountSelectorContext } from "~/components/context/accountContext";

export default function FreeTrial({ loggedIn }: { loggedIn: boolean }) {
  const openAccount = useAccountSelectorContext((state) => state.openAccount);

  const navigate = useNavigate();

  const handleEnter = useCallback(() => {
    console.log('logged in');
    if (loggedIn) {
      navigate("/app");
    } else {
      openAccount("click", "free_trial");
    }
  }, [loggedIn, navigate, openAccount]);

  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-theme-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">

      <div className="flex flex-wrap items-center gap-x-4 gap-y-2 text-white">
        <p className="text-sm leading-6 text-white">
          <strong className="font-bold">Free Trial</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Check us out for 14 days, no strings attached.
        </p>
        <div
          onClick={handleEnter}
          className="flex-none rounded-full bg-gray-50 px-3.5 py-1 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Try now <span aria-hidden="true">&rarr;</span>
        </div>
      </div>
      <div className="flex flex-1 justify-end">
        {/* <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
        </button> */}
      </div>
    </div>
  );
  return (
    <div className="bg-theme-100">
      <div className="mx-auto max-w-7xl px-6 py-12  lg:flex lg:items-center lg:justify-between lg:px-8">
        <div className="text-3xl font-extrabold tracking-tight text-theme-900 sm:text-4xl">
          {loggedIn ? `We're forking excited to see you again!` : "Ready to fork your first recipe?"}
          <br />
          {loggedIn ? "" : "Start your free trial today."}
        </div>
        <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
          <div
            className="rounded-md bg-theme-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-theme-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-600"
            onClick={handleEnter}
          >
            {loggedIn ? "Enter" : "Get started"}
          </div>
          {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Learn more <span aria-hidden="true">→</span>
          </a> */}
        </div>
      </div>
    </div>
  );
}
