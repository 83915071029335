import { Transition } from "@headlessui/react";
import { useNavigate } from "@remix-run/react";
import clsx from "clsx";
import { useCallback, useMemo, useState } from "react";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import BrandIcon from "~/components/icons/BrandIcon";
import { useInterval } from "~/utils/misc";

export default function Homepage({ recipes, loggedIn }) {
  const [wordIndex, setWordIndex] = useState(0);
  const wordList = useMemo(() => {
    return ["find", "save", "share", "cook", "plan", "shop"];
  }, []);
  const openAccount = useAccountSelectorContext((state) => state.openAccount);

  const navigate = useNavigate();

  const handleEnter = useCallback(() => {
    console.log('logged in');
    if (loggedIn) {
      navigate("/app");
    } else {
      openAccount("click", "free_trial");
    }
  }, [loggedIn, navigate, openAccount]);

  const changeWordIndex = useCallback(() => {
    const nextIndex = wordIndex + 1;
    if (nextIndex >= wordList.length) {
      setWordIndex(0);
    } else {
      setWordIndex(nextIndex);
    }
  }, [wordIndex, wordList]);

  const images = useMemo(() => {
    return (
      recipes?.map((recipe) => {
        return { src: recipe.imageFilename, alt: recipe.name, url: `/recipe/${recipe.urlId}/${recipe.urlKey}` };
      }) ?? []
    );
  }, [recipes]);

  useInterval(changeWordIndex, 2000);

  return (
    <div className="overflow-hidden">
      <div className="mx-auto w-full max-w-7xl flex-none pb-8 pt-4 sm:px-6 sm:pt-8 lg:px-8 lg:pt-4">
        <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
          <div className="flex w-full max-w-xl flex-col p-2 sm:p-0 lg:shrink-0 xl:max-w-2xl">
            <div className="mb-10 flex h-48 items-center space-x-4 self-center">
              <BrandIcon />
              {/* <h1 className="font-lexend text-5xl font-extrabold tracking-tighter">Forked</h1> */}
            </div>
            <h1 className="font-lexend text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              <div className="flex flex-col">
                <div className="flex-1">We’re changing the</div>
                <div className="flex flex-1">
                  <div className="flex-no">way you&nbsp;</div>
                  <span className="sr-only">{wordList.join(", ")}</span>
                  <div className="relative -z-10 flex-1 overflow-hidden">
                    {wordList.map((word, index) => {
                      return (
                        <Transition
                          key={word}
                          show={index === wordIndex}
                          appear={true}
                          enter={clsx("transition-[transform,opacity] delay-100")}
                          enterFrom="translate-y-full"
                          enterTo="translate-y-0"
                          leave={clsx("transition-[transform,opacity]")}
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 -translate-y-full"
                          className={clsx("absolute h-full w-full overflow-hidden text-theme-600 duration-1000")}
                        >
                          {word}
                        </Transition>
                      );
                    })}
                  </div>
                </div>
                <div className="flex-1">recipes.</div>
              </div>
            </h1>
            <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
              Your ad-free recipe app simplifying meal planning, streamlining recipe searches, and enhancing your cooking experience for ultimate kitchen mastery.
              {/* Why limit where you can find recipes? Forked lets you find recipes anywhere and keep them in one place. Try it out! Click on the search bar above
              and start searching for recipes! */}
            </p>
            <div className="mt-5 flex items-center justify-center gap-x-6">
              <div
                onClick={handleEnter}
                className="rounded-md cursor-pointer bg-theme-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-theme-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-600"
              >
                Get started
              </div>

            </div>
          </div>
          <ImagesWrapper images={images} />
        </div>
      </div>
      {/* <StickySections /> */}
    </div>
  );
}

const ImagesWrapper = ({ images }) => {
  return (
    <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
      <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
        <ImageContent image={images[0]} />
      </div>
      <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
        <ImageContent image={images[1]} />
        <ImageContent image={images[2]} />
      </div>
      <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
        <ImageContent image={images[3]} />
        <ImageContent image={images[4]} />
      </div>
    </div>
  );
};

const ImageContent = ({ image }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (image.url) {
      navigate(image.url);
    }
  };
  return (
    <div className="relative cursor-pointer" onClick={handleClick}>
      <img
        src={`${image?.src}?class=aspect23`}
        alt={image?.alt}
        loading="lazy"
        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
      />
      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
    </div>
  );
};
